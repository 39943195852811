import {MemberAddressModel} from '../models/MemberAddress.model';
import {AddressWithContactModel} from '../models/AddressWithContact.model';
import {AddressModel} from '../models/Address.model';
import {StreetAddressModel} from '../models/StreetAddress.model';
import {ContactModel} from '../models/Contact.model';
import {CommonVatId} from '@wix/ambassador-ecom-v1-checkout/types';

export const mapToAddressWithContactModel = (
  memberAddress?: MemberAddressModel
): AddressWithContactModel | undefined => {
  return memberAddress
    ? new AddressWithContactModel({
        address: new AddressModel({
          streetAddress: new StreetAddressModel({
            name: memberAddress.street?.name,
            number: memberAddress.street?.number,
          }),
          addressLine: memberAddress.addressLine1,
          addressLine2: memberAddress.addressLine2,
          city: memberAddress.city,
          postalCode: memberAddress.zipCode,
          country: memberAddress.country,
          subdivision: memberAddress.subdivision,
        }),
        contactDetails: new ContactModel({
          firstName: memberAddress.fullName.firstName,
          lastName: memberAddress.fullName.lastName,
          company: memberAddress.company,
          phone: memberAddress.phoneNumber,
          vatId: memberAddress.taxInfo?.id as Pick<CommonVatId, 'id'>,
        }),
      })
    : undefined;
};
