import {NavigationService} from '../services/NavigationService';
import {NavigationStoreProps} from '../../types/app.types';
import {SiteStore} from '@wix/wixstores-client-storefront-sdk';
import {CheckoutService} from '../services/CheckoutService';
import {BIService} from '../services/BIService';
import {CheckoutOrigin} from '../utils/bi.util';
import {APP_DEFINITION_ID, STORES_APP_DEF_ID} from '@wix/wixstores-client-core';
import {SPECS} from '../../components/Checkout/constants';
import {isPickupFlow} from '../utils/checkoutFlow.utils';
import {IWidgetControllerConfig} from '@wix/native-components-infra';
import {MemberService} from '../services/MemberService';

export class NavigationStore {
  private readonly biService: BIService;
  private readonly navigationService: NavigationService;
  private readonly siteStore: SiteStore;
  private readonly checkoutService: CheckoutService;
  private readonly memberService: MemberService;
  private readonly controllerConfig: IWidgetControllerConfig;
  private readonly updateComponent: () => void;

  constructor({
    navigationService,
    siteStore,
    checkoutService,
    memberService,
    biService,
    controllerConfig,
    updateComponent,
  }: {
    navigationService: NavigationService;
    siteStore: SiteStore;
    checkoutService: CheckoutService;
    memberService: MemberService;
    biService: BIService;
    controllerConfig: IWidgetControllerConfig;
    updateComponent: () => void;
  }) {
    this.navigationService = navigationService;
    this.siteStore = siteStore;
    this.checkoutService = checkoutService;
    this.memberService = memberService;
    this.biService = biService;
    this.controllerConfig = controllerConfig;
    this.updateComponent = updateComponent;
  }

  public navigateToOldCheckoutForUnsupportedFlow() {
    if (!this.isCurrentFlowSupported()) {
      this.biService.navigateToOldCheckout('not supported flow');
      this.navigationService.navigateToOldCheckout();
    }
  }

  public navigateToOldCheckoutForUnsupportedItems() {
    if (!this.areAllCheckoutItemsFromStores()) {
      this.biService.navigateToOldCheckout('non-stores', this.checkoutService.checkout.id);
      this.navigationService.navigateToOldCheckout();
    }
  }

  public async onLoadFailure() {
    this.biService.loadCheckoutFailed();
    /* istanbul ignore next */
    if (!this.navigationService.isLoaded) {
      await this.navigationService.load();
    }
    this.navigationService.navigateToContinueShopping();
  }

  private isCurrentFlowSupported() {
    const isFastFlow = Boolean(this.navigationService.cashierPaymentId);

    const _isPickupFlow = isPickupFlow({
      navigationService: this.navigationService,
      checkoutService: this.checkoutService,
    });

    const isVisitorPickupFlow =
      _isPickupFlow &&
      !this.memberService.isMember() &&
      this.siteStore.experiments.enabled(SPECS.UseNewCheckoutInVisitorPickup);

    const restOfTheFlows = this.siteStore.experiments.enabled(SPECS.UseNewCheckoutInRestOfTheFlows);

    return isFastFlow || isVisitorPickupFlow || restOfTheFlows;
  }

  private areAllCheckoutItemsFromStores() {
    return (
      this.checkoutService.checkout.lineItems.filter(
        ({catalogAppId}) => ![APP_DEFINITION_ID, STORES_APP_DEF_ID].includes(catalogAppId)
      ).length === 0
    );
  }

  private readonly clickOnContinueShopping = (origin?: CheckoutOrigin) => {
    this.biService.clickOnContinueShopping(this.checkoutService.checkout, origin);
  };

  private readonly clickOnBackToSite = () => {
    this.biService.clickOnContinueShopping(this.checkoutService.checkout);
    return this.navigationService.navigateToContinueShopping();
  };

  private readonly clickOnReturnToCart = async () => {
    return this.navigationService.navigateToCart(this.checkoutService.checkout.cartId);
  };

  private readonly clickOnEditCart = () => {
    this.biService.clickOnEditCart(this.checkoutService.checkout);
  };

  private readonly navigateToThankYouPage = (orderId: string) => this.navigationService.navigateToThankYouPage(orderId);

  private readonly onLogout = async (): Promise<void> => {
    this.biService.checkoutClickLogoutInCheckout(this.checkoutService.checkout);
    this.controllerConfig.wixCodeApi.user.logout();
    await this.navigationService.navigateToHomepage();
  };

  private readonly onLogin = async (): Promise<void> => {
    this.biService.checkoutClickLoginInCheckout(this.checkoutService.checkout);
    const user = await this.controllerConfig.wixCodeApi.user.promptLogin({mode: 'login'});
    if (user) {
      this.siteStore.experiments.enabled(SPECS.UseNewCheckoutInRestOfTheFlows)
        ? this.navigationService.reload()
        : this.navigationService.navigateToOldCheckout();
    }
  };

  public toProps(): NavigationStoreProps {
    return {
      clickOnBackToSite: this.clickOnBackToSite,
      clickOnContinueShopping: this.clickOnContinueShopping,
      clickOnReturnToCart: this.clickOnReturnToCart,
      clickOnEditCart: this.clickOnEditCart,
      continueShoppingUrl: this.navigationService.continueShoppingUrl,
      editCartUrl: this.navigationService.editCartUrl,
      navigateToThankYouPage: this.navigateToThankYouPage,
      trackEvent: (...args) => {
        this.controllerConfig.wixCodeApi.window.trackEvent(...args);
      },
      onLogin: this.onLogin,
      onLogout: this.onLogout,
    };
  }
}
